<template>
  <v-card class="elevation-12" :loading="loading">
    <v-toolbar color="info" flat dark>
      <v-toolbar-title>
        <span>Enter New Credentials</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on: on }">
          <v-btn icon v-on="on" :to="{ name: 'login' }">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Cancel") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <div v-if="!loading && passwordChanged">
        <h3>Your password has been changed successfully.</h3>
        <p>
          You will be redirected in 10 seconds to the login page. If not
          <router-link :to="{ name: 'login' }">click here</router-link>.
        </p>
      </div>
      <v-alert type="warning" v-if="!loading && !validKey">
        <p>Invalid Key attempting to change expired credentials.</p>
        <p>Please return to the login page.</p>
      </v-alert>
      <ApiError :errors="errors"></ApiError>
      <v-form :value="valid" @submit.prevent="onSubmit" v-model="valid" v-if="!loading && !passwordChanged && validKey">
        <v-container>
          <p>Please enter a new password which includes:</p>
          <ul v-if="programGroup">
            <li v-if="programGroup && programGroup.passwordMinimumCharacters">
              Minimum length of
              {{ programGroup.passwordMinimumCharacters }} characters
            </li>
            <li v-if="programGroup && programGroup.passwordMinimumDigits">
              Include at least {{ programGroup.passwordMinimumDigits }}
              {{ programGroup.passwordMinimumDigits == 1 ? "number" : "numbers" }}
            </li>
            <li v-if="programGroup && programGroup.passwordMinimumAlpha">
              Include at least {{ programGroup.passwordMinimumAlpha }}
              {{ programGroup.passwordMinimumDigits == 1 ? "letter" : "letters" }}
            </li>
            <li v-if="programGroup && programGroup.passwordMinimumSpecial">
              Include at least {{ programGroup.passwordMinimumSpecial }}
              {{ programGroup.passwordMinimumSpecial == 1 ? "special character" : "special characters" }}
              ({{ programGroup.passwordAllowableSpecialCharacters }})
            </li>
            <li v-if="programGroup && programGroup.passwordPreviousPasswordCheck">
              Not the same as a previous password
            </li>
            <li v-if="programGroup && programGroup.passwordUsernameCheck">
              Not the same as your username
            </li>
          </ul>
          <v-row>
            <v-col cols="12" v-if="programGroup">
              <PasswordField
                name="newPassword"
                label="New Password"
                id="newPassword"
                v-model="form.newPassword"
                :rules="rules.newPassword"
                :programGroup="programGroup"
                :error-messages="$error.getValidationError(errors, 'password')"
                @input="$error.clearValidationError(errors, 'password')"
              ></PasswordField>
              <PasswordField
                name="confirmPassword"
                label="Confirm Password"
                id="confirmPassword"
                v-model="form.confirmPassword"
                :rules="rules.confirmPassword"
                :programGroup="programGroup"
                :error-messages="$error.getValidationError(errors, 'confirmPassword')"
                @input="$error.clearValidationError(errors, 'confirmPassword')"
              ></PasswordField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn type="submit" color="primary" :loading="loading" :disabled="!valid">Update</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiError from "../display/ApiError";
import { mapGetters } from "vuex";
import PasswordField from "../fields/PasswordField.vue";
export default {
  name: "CredentialsExpiredForm",
  data() {
    return {
      valid: true,
      loading: false,
      form: {},
      programGroup: null,
      errors: {},
      rules: [],
      passwordChanged: false,
      validKey: false
    };
  },
  components: {
    ApiError,
    PasswordField
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.errors = {};
      this.form.key = this.$route.query.key;
      this.form.sendEmail = true;
      this.$api
        .post("/api/users/credentialsExpiredChangePassword", this.form)
        .then(() => {
          this.passwordChanged = true;
          setTimeout(() => {
            this.$router.push({ name: "login" });
          }, 10000);
        })
        .catch(error => {
          this.errors = this.$api.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchData() {
      this.loading = true;
      this.validKey = false;
      this.$api
        .get("/api/users/credentialsExpiredInfo?key=" + this.$route.query.key)
        .then(({ data }) => {
          this.programGroup = data;
          this.validKey = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  }
};
</script>
